<template>
  <div class="my-page review" ref="contentRef" :class="{skeleton: !state.review.loaded && !state.project.loaded}">
    <ul class="nav">
      <li class="nav-item" v-for="(m, idx) in menus" :key="idx">
        <router-link :to="m.path" class="btn" :class="[$route.path === m.path ? 'active btn-bordered-point' : 'btn-default']" :disabled="!state.review.loaded && !state.project.loaded">{{ m.title }}</router-link>
      </li>
    </ul>
    <template v-if="$route.params.param2 === 'written'">
      <div v-if="state.review.list.length > 0 || !state.review.loaded">
        <ReviewCard
            v-for="(r, idx) in state.review.list" :key="idx"
            :skeleton="r.skeleton || !state.review.loaded"
            :content="r.content"
            :createDate="r.createDate"
            :delFlag="r.delFlag"
            hideInvestorNameYn="N"
            :reviewId="r.id"
            :replies="r.replies"
            :memberName="$store.state.account.memberName"
            :memberSeq="r.memberSeq"
            :photos="r.photos"
            :selectedTags="r.selectedTags"
            :updateDate="r.updateDate"
            :projectName="r.projectName"
            :projectSeq="r.projectSeq"
            :blindFlag="r.blindFlag"
        />
      </div>
      <div v-else>
        <NoData text="작성한 후기가 없습니다."/>
      </div>
    </template>
    <template v-else>
      <template v-for="(p, idx) in state.project.list">
        <div class="list" :class="{skeleton: !state.project.loaded || p.skeleton}" :key="idx" v-if="(!state.project.loaded || state.project.list.length) && p.rewardTitle !== '리워드 없이 참여'">
          <div class="thumb">
            <router-link :to="`/reward/${p.projectSeq}`" class="img" title="프로젝트로 이동"
                         :style="{backgroundImage: `url('${$definitions.urls.imgCdn + p.thumbFilePath}')`, backgroundPosition: p.mainImagePosition || 'center'}"></router-link>
          </div>
          <div class="texts">
            <div class="reward">
              <span>{{ p.rewardTitle }}</span>
            </div>
            <div class="title">
              <Anchor :href="`/reward/${p.projectSeq}`" :title="p.projectName">{{ p.projectName }}</Anchor>
            </div>
            <div class="date">
              <span>{{ p.regDate }}</span>
            </div>
          </div>
          <div class="act">
            <button class="btn btn-point font-xs" @click="openReview(p.investorSeq, p.projectSeq)">
              후기 작성
            </button>
          </div>
        </div>
      </template>
      <NoData text="작성 가능한 후기가 없습니다." v-if="state.project.loaded && !state.project.list.length"/>
    </template>
  </div>
</template>
<script>
import mixin from "@/scripts/mixin";
import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import ReviewCard from "@/components/ReviewCard";
import store from "@/scripts/store";
import http from "@/scripts/http";
import NoData from "@/components/NoData";
import router from "@/scripts/router";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "pageMyPageReview";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, NoData, ReviewCard},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (router.app.$route.params.param2 === "written") {
        loadReview();
      } else if (router.app.$route.params.param2 === "unreviewed") {
        loadProject();
      }
    });

    const state = reactive({
      review: {
        loaded: false,
        more: false,
        pageable: {
          page: 0,
          size: 0,
        },
        total: 0,
        list: [],
      },
      project: {
        loaded: false,
        more: false,
        pageable: {
          page: 0,
          size: 0,
        },
        total: 0,
        list: [],
      },
      args: {
        page: 0,
        size: 10,
        sort: "regDate,desc"
      }
    });

    const menus = [{
      name: "unreviewed",
      title: "작성 대기 목록",
      path: "/mypage/review/unreviewed",
    }, {
      name: "written",
      title: "작성 목록",
      path: "/mypage/review/written",
    }];
    const contentRef = ref();

    const loadReview = (more) => {
      state.args.sort = "createDate,desc";

      if (!more) {
        state.review.loaded = false;
        state.args.page = 0;
      } else {
        state.args.page += 1;
        state.review.more = true;
      }

      for (let i = 0; i < 5; i += 1) {
        state.review.list.push({
          skeleton: true,
          content: "Wait a moment",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          selectedTags: "wait,wait,wait,wait,wait",
        });
      }

      http.get(`/api/project/member/${store.state.account.memberSeq}/reviews`, state.args).then(({data}) => {
        if (!more) {
          state.review.loaded = true;
          state.review.list = data.body.content;
          state.review.pageable = data.body.pageable;
          state.review.total = data.body.total;
        } else {
          state.review.more = false;
          state.review.list = state.review.list.filter(i => !i.skeleton);

          for (let i in data.body.content) {
            state.review.list.push(data.body.content[i]);
          }
        }
      });
    };

    const loadProject = (more) => {
      state.args.sort = "regDate,desc";

      if (!more) {
        state.args.page = 0;
        state.project.loaded = false;
      } else {
        state.args.page += 1;
        state.project.more = true;
      }

      for (let i = 0; i < 3; i += 1) {
        state.project.list.push({
          projectName: "Wait a moment",
          rewardTitle: "Wait",
          regDate: "2021-12-20",
          skeleton: true,
        });
      }

      http.get(`/api/project/member/${store.state.account.memberSeq}/unreviewed`, state.args).then(({data}) => {
        if (!more) {
          state.project.loaded = true;
          state.project.list = data.body.content;
          state.project.pageable = data.body.pageable;
          state.project.total = data.body.total;
        } else {
          state.project.more = false;
          state.project.list = state.project.list.filter(i => !i.skeleton);

          for (let i in data.body.content) {
            state.project.list.push(data.body.content[i]);
          }
        }
      });
    };

    const openDetail = (detailSeq) => {
      store.commit("openModal", {
        name: "Participant",
        params: {
          detailSeq,
          projectType: "reward"
        },
        callback: `${component.name}.load`
      });
    };

    const openReview = (investorSeq, projectSeq) => {
      store.commit("confirm", {
        message: "리워드를 받으셨나요? 꼭 받으신 후에 작성해주세요!",
        allowTxt: "받았어요",
        disallowTxt: "아직 받지 못했어요",
        allow() {
          store.commit("openModal", {
            name: "Review",
            params: {
              investorSeq,
              projectSeq
            }
          });
        },
        disallow() {
          store.commit("setSwingMessage", "리워드를 받으신 후에 후기를 작성해주세요.");
        }
      });
    };

    const onScroll = () => {
      if (router.app.$route.params.param2 === "written") {
        if (!state.review.loaded || state.args.page >= (Math.ceil(state.review.total / state.review.pageable.size) - 1) || state.review.more) {
          return;
        }

        (contentRef.value?.querySelector(".review-card").offsetHeight) * 2 >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
        && loadReview(true);
      } else if (router.app.$route.params.param2 === "unreviewed") {
        if (!state.project.loaded || state.args.page >= (Math.ceil(state.project.total / state.project.pageable.size) - 1) || state.project.more) {
          return;
        }

        (contentRef.value?.querySelector(".list").offsetHeight) * 5 >= document.body.offsetHeight - (window.innerHeight + window.scrollY)
        && loadProject(true);
      }
    };

    onMounted(() => {
      store.commit("addListener", [component.name, "onScroll", onScroll]);
    });

    return {component, state, contentRef, menus, openDetail, openReview};
  }
});
</script>
<style lang="scss" scoped>
@import "../../styles/page.mypage";

.review {
  .list {
    display: flex;
    align-items: center;

    .thumb {
      width: $px120;

      .img {
        background-color: $colorBackground;
        width: 100%;
        padding-top: $ratio43Percent;
        border-radius: $px4;
        border: $px1 solid #eee;
        background-size: auto 100%;
      }
    }

    .texts {
      font-size: $px14;
      padding-left: $px20;
      flex: 1 0 0;
      overflow: hidden;

      .reward {
        color: $colorSecondary;
        font-size: $px12;
        margin-bottom: $px10;
        overflow: hidden;
        padding-right: $px150;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .title {
        line-height: 1;
        margin-bottom: $px10;

        a {
          font-size: $px16;
          font-weight: 500;
          display: inline-block;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding-right: $px10;
        }
      }

      .date {
        color: $colorSecondary;
        font-size: $px10;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .act {
      .btn {
        padding: $px12 $px15;
      }
    }

    &:not(:first-child) {
      margin-top: $px20;
    }

    &.skeleton {
      .thumb .img {
        @include skeleton;
      }

      .texts {
        .reward span, .title > a, .date span {
          @include skeleton;
        }
      }

      .act button {
        @include skeleton
      }
    }
  }

  &.skeleton {
    .nav .nav-item > a {
      @include skeleton;
    }
  }

  @media (max-width: 991px) {
    .list {
      .thumb {
        width: $px80;
      }

      .texts {
        padding-left: $px10;

        .reward {
          margin-bottom: $px5;
          font-size: $px10;
        }

        .title {
          margin-bottom: $px5;

          a {
            font-size: $px12;
          }
        }

        .date {
          font-size: $px8;
        }
      }

      .act {
        .btn {
          padding: $px10;
        }
      }
    }
  }
}
</style>